@use '@angular/material' as mat

@include mat.core()

$indigo-palette: (50: #F3E2E2,100: #F1DCDC, 200: #EFD6D7, 300: #EDD0D1, 400: #EBCACB,500: #e9c5c6,600: #D1B1B2,700: #BA9D9E,800: #A3898A,900: #2E2727, contrast: (50: rgb(46,39,39), 100: rgb(46,39,39), 200: rgb(46,39,39), 300: #F6E7E8))

$my-primary: mat.define-palette($indigo-palette, 900)
$my-accent: mat.define-palette($indigo-palette, 500, 100, 900)

$my-theme: mat.define-light-theme(( color: (primary: $my-primary,accent: $my-accent ), density: 0))

// Emit theme-dependent styles for common features used across multiple components.
@include mat.core-theme($my-theme)

// Emit styles for MatButton based on `$my-theme`. Because the configuration
// passed to `define-light-theme` omits typography, `button-theme` will not
// emit any typography styles.
@include mat.button-theme($my-theme)

@include mat.all-component-themes($my-theme)

html
    height: 100%
    margin: 0
    padding: 0
body
    min-height: 100%
    margin: 0
    padding: 0
    font-family: 'Open Sans', sans-serif

:host
    height: 100vh
    font-family: 'Open Sans', sans-serif
.snackbar-message
    background-color: #DCA34E !important
.mat-calendar-body-selected
  background-color: #DCA34E !important
  color: white !important
